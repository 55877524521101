@font-face {
    font-family: Roboto;
    src: url(./fonts/Roboto-Light.ttf);
    font-weight: 300;
}
@font-face {
    font-family: Roboto;
    src: url(./fonts/Roboto-LightItalic.ttf);
    font-weight: 300;
    font-style: italic;
}
@font-face {
    font-family: Roboto;
    src: url(./fonts/Roboto-Regular.ttf);
}
@font-face {
    font-family: Roboto;
    src: url(./fonts/Roboto-Italic.ttf);
    font-style: italic;
}
@font-face {
    font-family: Roboto;
    src: url(./fonts/Roboto-Medium.ttf);
    font-weight: 500;
}
@font-face {
    font-family: Roboto;
    src: url(./fonts/Roboto-MediumItalic.ttf);
    font-weight: 500;
    font-style: italic;
}
@font-face {
    font-family: Roboto;
    src: url(./fonts/Roboto-Bold.ttf);
    font-weight: bold;
}
@font-face {
    font-family: Roboto;
    src: url(./fonts/Roboto-BoldItalic.ttf);
    font-weight: bold;
    font-style: italic;
}
@font-face {
    font-family: Roboto;
    src: url(./fonts/Roboto-Black.ttf);
    font-weight: 900;
}
@font-face {
    font-family: Roboto;
    src: url(./fonts/Roboto-BlackItalic.ttf);
    font-weight: 900;
    font-style: italic;
}

.pwa_content p,
.pwa_content span,
.pwa_content div {
    color: #000 !important;
}

.pwa_container {
    width: 100%;
    max-width: 380px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    padding: 17px 0 50px;
    background: #fff;
    border-radius: 20px;
    height: 840px;
    overflow-y: scroll;
}
.pwa_content {
    width: 100%;
    max-width: 480px;
    margin: 0 auto;
    padding-bottom: 20px;
}

.container {
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
}

.about {
    max-width: 640px;
    margin: 0 auto;
    display: flex;
    padding: 10px 20px 0;
}
.about__icon {
    width: 70px;
    height: 70px;
    min-width: 70px;
}
.about__icon-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
}
.about__text-info {
    display: flex;
    flex-direction: column;
    margin: 5px 5px 15px 25px;
}
.about__name-pwa {
    font-size: 22px;
    line-height: 24px;
    max-width: 240px;
    color: #000;
}
.about__info-developer {
    display: flex;
    padding-top: 5px;
}
.about__developer-name {
    color: #0d57cf;
    font-size: 13px;
    margin-right: 15px;
    line-height: 15px;
    font-weight: 500;
}

.info-app {
    max-width: 640px;
    margin: 10px auto;
    padding-bottom: 15px;
}
.info-app__content {
    display: flex;
    overflow-x: scroll;
    padding-bottom: 10px;
}
.info-app__item {
    height: 40px;
    width: 33%;
    min-width: 110px;
    border-right: 1px solid #ddd;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.info-app__icon-download {
    width: 23px;
    height: 23px;
    object-fit: contain;
}
.info-app__item:last-child {
    border: none;
}
.info-app__item-content {
    display: flex;
    align-items: center;
    padding: 3px 0;
}
.info-app .age-content {
    border: 1px solid #000;
    padding: 2px 4px;
    margin-top: 3px;
}
.info-app .age-content span {
    font-size: 13px;
    color: #000;
}
.info-app .installed span {
    font-size: 13px;
    font-weight: 500;
    color: #000;
}
.info-app__description {
    font-size: 12px;
    color: #000;
}

.info-app__description span {
    color: #000;
}

.info-app .rating span {
    font-size: 13px;
    font-weight: 500;
    color: #000;
}
.info-app .rating svg {
    width: 17px;
    height: 17px;
}

.list-categories {
    width: 100%;
}

.list-categories-container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding: 20px 15px;
}

.list-categories__item {
    width: max-content;
    text-align: center;
    margin-right: 15px;
    margin-bottom: 15px;
    border: 1px solid #000;
    border-radius: 20px;
    padding: 5px 10px;
    font-size: 13px;
    color: #000;
}

.data_security {
    margin: 0 20px 20px;
}

.data_security__content {
    padding: 26px;
    border-radius: 7px;
    border: 1px solid #C8C8C8;
}

.data_security h1 {
    color: #282828;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    margin-bottom: 22px;
}

.data_security__content p {
    color: #484848;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}

.data_security__content span {
    color: #000;
}

.data_security__content p:last-child {
    margin-bottom: 0;
}

.data_security p img {
    margin-right: 15px;
}

.button-action {
    max-width: 640px;
    margin: 0 auto;
    padding: 0 20px;
}
.button-action__button-container {
    background: #0d57cf;
    border-radius: 20px;
}
.button-action__button {
    color: #fff;
    width: 100%;
    text-align: center;
    font-size: 15px;
    padding: 10px;
}

.gallery {
    padding: 20px 20px 0;
    max-width: 640px;
    margin: 0 auto;
}
.gallery__container {
    display: flex;
    overflow-x: scroll;
    padding-bottom: 10px;
}
.gallery__item img {
    padding: 5px;
    border-radius: 20px;
    max-height: 210px;
}

.about-app {
    padding: 20px;
    max-width: 640px;
    margin: 0 auto;
}
.about-app__title {
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    color: #636b6f;
    margin-bottom: 20px;
}
.about-app__text-content {
    text-align: left;
    font-size: 14px;
    line-height: 24px;
    color: #333;
    height: 120px;
    overflow: hidden;
    white-space: pre-wrap;
}
.about-app__open-more {
    text-align: center;
    padding: 20px 0 0;
    color: #0d57cf;
    font-size: 14px;
    line-height: 19px;
    position: relative;
    margin-bottom: 30px;
    cursor: pointer;
}
.about-app__line-gray {
    height: 1px;
    width: 100%;
    padding: 0 30px;
    background: #636b6f;
    opacity: 0.3;
}

.rating_block {
    padding: 20px 20px 0;
    max-width: 640px;
    margin: 0 auto;
}
.rating_block__title {
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    color: #636b6f;
    margin-bottom: 20px;
}
.rating_block__content {
    display: flex;
    justify-content: space-between;
}
.rating_block__number-rating {
    width: 130px;
    padding-right: 20px;
    margin-bottom: 20px;
    text-align: center;
}
.rating_block__number {
    font-size: 64px;
    line-height: 68px;
    font-weight: 400;
    color: #000;
}
.rating_block__star-list {
    display: flex;
    justify-content: center;
}
.rating_block__star {
    width: 12px;
    height: 12px;
    display: inline-block;
    margin-right: 5px;
}
.rating_block__star:last-child {
    margin-right: 0;
}
.rating_block__star img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.rating_block__count-reviews {
    margin-top: 10px;
    text-align: center;
    color: #636b6f;
    font-size: 11px;
    line-height: 14px;
}
.rating_block__chart-list {
    width: 100%;
}
.rating_block__chart-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.rating_block__chart-item progress {
    width: 100%;
}
.rating_block__chart-item span {
    font-size: 10px;
    line-height: 12px;
    color: #636b6f;
    display: inline-block;
    margin-right: 8px;
}
.rating_block__chart-bar {
    position: relative;
    width: 100%;
}
.rating_block__chart-bar .bg {
    width: 100%;
    background: #d9d9d9;
    height: 8px;
    border-radius: 10px;
}
.rating_block__chart-bar .bar {
    width: 75%;
    height: 8px;
    position: absolute;
    top: 0;
    left: 0;
    background: #0d57cf;
    border-radius: 10px;
}

.reviews {
    padding: 0 20px;
    max-width: 640px;
    margin: 0 auto;
}
.reviews__item {
    margin-bottom: 40px;
}
.reviews__top-info {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}

.reviews__name-reviewer {
    color: #000;
}

.name-response {
    color: #000;
}

.reviews__logo-container {
    width: 48px;
    height: 48px;
    margin-right: 30px;
}
.reviews__logo-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 100%;
}
.reviews__name-reviewe {
    font-size: 13px;
    line-height: 16px;
}
.reviews__rating_date {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 15px;
}
.reviews__rating {
    margin-right: 15px;
}

.reviews__rating img {
    width: 9px;
    height: 9px;
    object-fit: contain;
    display: inline-block;
    margin-right: 3px;
}
.reviews__rating span {
    display: inline-block;
    width: 12px;
    height: 12px;
}
.reviews__rating span img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.reviews__date {
    font-size: 14px;
    color: #636b6f;
    line-height: 17px;
}
.reviews__text-comment {
    font-size: 13px;
    line-height: 18px;
    color: #636b6f;
    margin-bottom: 20px;
}
.reviews__developer-response {
    padding: 10px;
    position: relative;
    background: #e5e5e5;
    margin-left: 30px;
}
.reviews__developer-response:before {
    content: "";
    position: absolute;
    top: -13px;
    left: 15px;
    width: 0px;
    height: 0px;
    border-style: solid;
    border-width: 0 10.5px 15px 10.5px;
    border-color: transparent transparent #e5e5e5 transparent;
    transform: rotate(0deg);
}
.reviews__developer-response .name-response {
    font-size: 15px;
    line-height: 20px;
    display: inline-block;
    margin-bottom: 10px;
}
.reviews__developer-response .text {
    font-size: 13px;
    line-height: 18px;
    color: #636b6f;
}

.recommended-app {
    margin: 20px 10px;
}
.recommended-app__title-block {
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 30px;
    color: #000;
}

.recommended-app__title-block p,
.recommended-app__title-block span {
    color: #000;
}
.recommended-app__list-app {
    display: flex;
    overflow-x: scroll;
    padding-bottom: 10px;
}
.recommended-app__item {
    margin-right: 30px;
}
.recommended-app__logo {
    width: 120px;
    height: 120px;
    margin-bottom: 15px;
}
.recommended-app__logo img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
}
.recommended-app__name-app {
    font-size: 14px;
    margin-bottom: 5px;
    color: #000;
}
.recommended-app__rating-app {
    font-size: 14px;
    position: relative;
    display: flex;
    align-items: center;
    color: #000;
}
.recommended-app__rating-app span {
    display: inline-block;
    margin-right: 5px;
    color: #000;
}
.recommended-app__rating-app img {
    width: 12px;
    height: 12px;
    object-fit: contain;
    display: inline-block;
    margin-left: 8px;
}