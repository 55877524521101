.MainSection {
  width: 100%;
  height: 100%;
  max-width: 900px;
  margin: 0 auto;
  padding: 0 20px;
  display: grid;
  grid-template-columns: 1fr; /* одна колонка */
  grid-template-rows: auto auto;

  //display: flex;
  //justify-content: space-between;

  &_NavigationEditSection {
    width: 100%;
    max-width: 50%;
  }
}

.PreviewSection {
  width: 100%;
  background: #0E0E0E;
  //display: flex;
  //justify-content: center;
  grid-column: 2 / 2; /* початок на першій колонці */
  grid-row: 2 / 4;
}