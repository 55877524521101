.PreviewContainer {
  width: 100%;
  max-width: 430px;
  display: flex;
  flex-direction: row-reverse;
}



.HeaderPreview {
  display: flex;
  justify-content: center;
}

.InfoDownload {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

   &_NameLand {
     width: 100%;
     max-width: 283px;
     padding: 0 10px;
     background: #0E0E0E;
     border-bottom-left-radius: 10px;
     border-bottom-right-radius: 10px;
     margin-right: 10px;

     &_Text {
       text-align: center;
       font-size: 20px;
       line-height: 23px;
       font-weight: normal;
       padding: 14px 0;
       overflow: hidden;
       white-space: nowrap;
       text-overflow: ellipsis;
     }
   }

  &_SendLand {
    width: 45px;
    height: 45px;
    background: rgba(230,66,71,.2);
    border-radius: 10px;
    cursor: pointer;
    border: 1px solid red;
    margin-left: 15px;

    &_Button {
      width: 100%;
      height: 100%;
      display: flex;
      padding: 10px;
      align-items: center;
      justify-content: center;

      .IconSend {
        width: 25px;
        height: 23px;
        object-fit: contain;
      }
    }
  }
}