.TextAriaField {
  width: 100%;
  border: 1px solid #363738;
  outline: none;
  background: #262626;
  color: #fff;
  padding: 6px;
  border-radius: 5px;
  font-size: 15px;
  font-family: MontserratMD;
}