.Container {

  .OfferLink {
    margin-bottom: 50px;
    p {
      //color: #
    }

    input {
      max-width: 100%;
    }
  }

  .SubItemList {

    p {
      margin-bottom: 21px;
    }

    &__Content {
      display: flex;
      flex-wrap: wrap;
    }

    &__Item{
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      max-width: 182px;
      margin-bottom: 20px;
      margin-right: 20px;
    }

    &__InputsContainer {
      width: 100%;
      max-width: 182px;

      input {
        max-width: 182px;

        &:first-child {
          display: inline-block;
          margin-bottom: 8px;
        }
      }
    }

    &__ButtonAction {
      width: 140px;
      height: 90px;
      display: flex;
    }

    &__Button {
      padding-top: 2px;
      cursor: pointer;
      width: 40px;
      height: 40px;
      border: 1px solid #363738;
      border-radius: 11px;
      margin-bottom: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 22px;
      line-height: 1;
      margin-right: 20px;
    }
  }
}

