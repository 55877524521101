.container {
  width: 100%;
  max-width: 1440px;
  padding: 23px 20px;
  margin: 0 auto;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.LogoNavBlock {

  .LogoContainer {
    width: 100%;
    max-width: 165px;
    height: 25px;

    .LogoImage {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}

.LanguagesSelectContainer {

  .LanguagesList {
    width: 50px;

    &_Item {
      text-align: center;
      padding: 10px 0;
      font-size: 14px;

      &:last-child {
         display: none;
      }
    }
  }
}

.RightColum {
  display: flex;
  align-items: center;

  .TelegramButton {
    display: flex;
    justify-content: center;
    width: 133px;
    height: 44px;
    background: #363636;
    border-radius: 10px;
    margin-right: 10px;

    a{
      text-decoration: none;
      display: flex;
      align-items: center;
      font-size: 14px;
      //padding: 0 20px;

      img {
        display: inline-block;
        width: 20px;
        height: 20px;
        object-fit: contain;
        margin-left: 10px;
      }
    }
  }
}