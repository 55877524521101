.NavigationSection {
  margin-bottom: 20px;
  //display: flex;
  grid-column: 1 / 3; /* початок на першій колонці */
  grid-row: 1 / 2; /* початок на першому рядку */
}

.NavigationLinkContainer {
  background: #0E0E0E;
}

.TabsEditContent {
  //width: 301px;
  grid-column: 1 / 2; /* початок на першій колонці */
  grid-row: 2 / 3;
}

.NavigationLinkList {
  //width: 301px;
  display: flex;
  justify-content: space-around;
  width: 100%;

  //border: 1px solid red;
}

.NavigationItemList {
  width: 200px;
  border-radius: 10px;

  .Link {
    border: 1px solid gray;
    border-radius: 15px;
    display: inline-block;
    width: 100%;
    height: 100%;
    text-decoration: none;
    font-size: 16px;
    padding: 15px 30px;
    background: #262626;
    text-align: center;
    //padding: 15px 0 15px 95px;

    &.active {
      background: rgba(230,66,71,.2);
      border-radius: 15px;
      border: 1px solid #E64247;
    }
  }
   > .active {
    border: none
  }
}