.TabContent {
  width: 316px;
  padding: 0 20px 18px;
}

.CountReview {
  margin-bottom: 24px;

  .SelectList {
    width: 100%;

    .css-lkh0o5-menu {
      z-index: 6;
    }
  }

  &_Radio {

    label {

    }
  }
}

.TemplateReviews {
  margin-bottom: 24px;

  &_CheckboxTemplate {
    //display: flex;
    align-items: center;
    margin-bottom: 15px;
    display: none;

    &_Text {
      font-size: 14px;
      margin-left: 10px;
    }
  }

  &_Languages {

  }
}

.ReviewsBlock {

}

.ReviewBlockItem {
  margin-bottom: 15px;

  label {
    display: inline-block;
    margin-bottom: 6px;
    color: #878787;
    font-size: 14px;
    font-weight: 400;
  }

  &_TextTemplateReview {
    font-size: 13px;
    font-weight: normal;
    line-height: 1.3;
  }
}


.InputField {
  display: inline-block;
  width: 100%;
  max-width: 280px;
  padding: 13px 15px;
  font-size: 15px;
  border-radius: 10px;
  border: 1px solid #363738;
  outline: none;
  background: #262626;
  color: #fff;
}

.CommentTitleShow {
  border: 1px solid #FF2A00;
  padding: 10px;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ArrowCommentTitle {
  width: 25px;
  height: 24px;
  object-fit: contain;
}

.CommentInfoContainer {
  padding-top: 20px;
  //display: none;

  &.active {
    display: block;
  }
}

.ItemComment {
  margin-bottom: 20px;

  input[type=text],
  input[type=number] {
    padding: 8px 15px;
    display: inline-block;
    width: 100%;
    max-width: 280px;
    font-size: 15px;
    border-radius: 10px;
    border: 1px solid #363738;
    outline: none;
    background: #262626;
    color: #fff;
  }

  textarea {
    width: 100%;
    border: 1px solid #363738;
    outline: none;
    background: #262626;
    color: #fff;
    padding: 6px;
    border-radius: 5px;
    font-size: 15px;
    font-family: Roboto, serif;
  }
}

.ContainerShortInfoCommentator {
  width: 100%;
}

.LogoReview {}

.ResponseField {
  margin-top: 10px;
  margin-bottom: 10px;
  color: rgba(135, 135, 135, 0.94);
  font-size: 12px;
  display: flex;
  flex-direction: column;

  span {
    display: inline-block;
    line-height: 14px;

    &:first-child {
      margin-bottom: 5px;
    }
  }
}

.DownloadIconApp_Container {
  position: relative;
  display: flex;
  align-items: center;
  padding: 10px ;
  border-radius: 10px;
  border: 1px solid red;
  width: max-content;

  input {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    z-index: 4;
  }

  span {
    font-size: 13px;
  }
}

.Icon_Logo_download {
  width: 30px;
  height: 30px;
  object-fit: contain;
  display: inline-block;
  margin-right: 10px;
}

.DownloadIconApp_IconButtonDownload {}

.CommentatorName {

}

.CommentatorRating {}

.CommentDate {}

.TextComment {}

.DeveloperResponse {}
